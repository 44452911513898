@font-face {
    font-family: "Proxima-Nova-Alt-Bold";
    src: url("../font/Proxima-Nova-Alt-Bold.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-Nova-Alt-Light";
    src: url("../font/Proxima-Nova-Alt-Light.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-Nova-Alt-Thin";
    src: url("../font/Proxima-Nova-Alt-Thin.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-Nova-Black";
    src: url("../font/Proxima-Nova-Black.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-Nova-Bold";
    src: url("../font/Proxima-Nova-Bold.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-Nova-Extrabold";
    src: url("../font/Proxima-Nova-Extrabold.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Regular";
    src: url("../font/ProximaNova-Regular.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima-Nova-Thin";
    src: url("../font/Proxima-Nova-Thin.otf") format(".otf");
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Semibold";
    src: url("../font/proximanova-semibold.otf") format(".otf");
    font-display: swap;
}

* {
    font-family: 'Proxima Nova Semibold', sans-serif;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
}

.errorMessage {
    border: 1px solid #ee9aa2;
    border-radius: 4px;
    background-color: #f5d5d6;
    color: #e22a40;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: "Proxima Nova Semibold", sans-serif;
    margin-top: 12px;

}

body,
html {
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

.j_anchor {
    text-decoration: none;
    color: #000000;
}
.customModal {
    background: #b2dbbf;
    max-width: 500px;
    width: 100%;
  }
  .react-responsive-modal-modal{
    max-width: 500px !important;
    width: 100% !important;
  }

.j_anchor:hover {
    text-decoration: none;
    color: #000000;
}
#carouselButtons {
    margin-left: 100px;
    position: absolute;
    bottom: 0px;
}


/* Header */
.j_stickyhead1 {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    color: #000000;
    margin-top: 14px;
}

#txtCallbackDate {
    width: 100%;
    /* height: 52px !important; */
    border: 1px solid #E7E4E4 !important;
    border-top: 0px !important;
    padding-left: 12px !important;
}

.date-picker-days i {
    height: 20px !important;
}

.picker-mask {
    z-index: 9999 !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.sticky-head {

    position: fixed;
    top: 0;
    background-color: #FFFFFF;
    z-index: 1;
}

.sticky-form {
    z-index: 1;
    position: fixed;
    top: 10%;
    float: right;
    right: -6px;
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
    transition: all .4s ease 0s;
}

.j_heighthead {
    /* background: #1D1C41;
    height: 70px; */
    height: 100px;

    background-color: #ffffff;
}

.j_telright {
    text-align: right;
    text-align: -webkit-right;
    text-align: -moz-right;
}

.j_telephone a {
    font-family: "Proxima Nova Semibold", sans-serif;
}

.j_telephone {
    background: rgba(0, 0, 0, 0.86);
    /* margin: 11px; */
    width: 100%;
    border: 1px solid;
    text-align: center;
    border-radius: 20px;
    padding: 7px;
    /* margin-top: 15px; */
    font-family: "Proxima Nova Semibold", sans-serif;
}

.j_telanchor {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;

}

.breadcrumb {
    background-color: #FFFFFF !important;
}

.j_breadcrum {
    font-size: 14px;
    font-weight: 600;
    background-color: aliceblue;
}

.breadcrumb-item {
    opacity: 0.6;
}

.j_shobhatitle {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
    color: #283048;
}

.j_shobhasubtitle {
    font-size: 20px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.67);
    margin-top: -6px;
}

.j_bredanchor {
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 12px;
}

.owl-nav {
    font-weight: 800 !important;
    margin-top: -70px !important;
    /* text-align: center !important; */
    /* -webkit-tap-highlight-color: transparent; */
    /* float: right; */
    font-size: 40px !important;
    position: absolute !important;
    margin-left: 600px !important;
}



.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: #000000 !important;
    color: white !important;
    border: 10px;
    padding: 0 !important;
    font: inherit;
    height: 40px !important;
    width: 40px !important;
}

.j_bhk {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #000000;
}

.j_config {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: #676767;
    opacity: 6.4;
    margin-top: -4px;
}

.j_overview {
    /* margin-left: 17px; */
    /* min-height: 369px; */
    border: 1px solid #FFFFFF;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(40, 48, 72, 0.13);
    padding-bottom: 24px;
}

.j_overviewhead {
    font-size: 24px;
    /* line-height: 24px; */
    font-weight: bold;
    /* margin-top: 10px; */
    /* padding-top: 10px; */
    height: 100%;
    display: flex;
    align-items: center;

}

.j_similerhead {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px;
    padding-left: 0;
}

.j_oversubpara {
    font-size: 14px;
    line-height: 14px;
    color: #676767;
}

.j_overpara {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #000000;
    margin-top: -9px;
    overflow: hidden;
    line-break: auto;
}

.j_parahead {
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    font-weight: bold;

    padding-bottom: 4px;

}

.j_para {
    font-size: 14px;
    line-height: 22px;
    color: #676767;
    padding-left: 1px;
    justify-content: center;
    margin-top: 5px;
    font-family: "Proxima-Nova-Alt-Light", sans-serif !important;
    padding: 0px 24px;
}

.paddingLeft {
    padding: 0px 24px !important;
}

.j_floorplan {
    min-height: 325px;
    border: 1px solid #FFFFFF;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(40, 48, 72, 0.13);
}



.j_video {
    /* min-height: 526px; */
    border: 1px solid #FFFFFF;
    padding-bottom: 25px !important;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(40, 48, 72, 0.13);
}

.owl-dots {
    display: none;
}

.owl-prev {}

.j_slider {
    margin-left: 15px;
}

.j_border {
    background: #1D1C41;
    height: 50px;
    width: 97%;
    margin: 0 8px;
    /* margin-left: 12px;
    margin-right: 23px; */
    color: white;
    text-align: right;
    font-size: 15px;
    margin-bottom: 0;
    padding-right: 20px;
    padding-top: 8px;
}

.j_videoplay {
    height: 411px;
    /* width: 200%; */

    padding-left: 30px;
    padding-right: 23px;
}

.j_llfirst {
    margin-right: 100px;
    margin-top: -70px;
    position: relative;
}

.j_llsecond {
    margin-top: -70px;
    position: inherit;
    margin-right: 61px;
}

.j_kkk {
    background: rgba(0, 0, 0, 0.6);
    color: white;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.chevron_size{
    font-size: 23px;
    font-weight: bold;
}


.embed-responsive {
    position: relative;
    display: block;
    margin: 0 8px;
    width: 97%;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
}

.j_amenties {
    min-height: 361px;
    border: 1px solid #FFFFFF;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(40, 48, 72, 0.13);
}

.j_paraamenties {
    font-size: 14px;
    line-height: 22px;
    color: #676767;
    padding-left: 1px;
    font-weight: bold;
    /* justify-content: center */

}

.j_sticky {
    margin-top: 35px;
}

.j_divfontawesome {
    border: 1px solid #F4F4F4;
    width: 100px;
    height: 100px;
    float: left;
    /* margin-left: 18px;
    margin-bottom: 10px; */
    background: #F4F4F4;
    margin-left: 3px;
}

.j_localityli {
    margin-top: 5px;
    font-size: 14px;
    line-height: 22px;
    color: #676767;
}

.j_localityview {
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    color: #1D1C41;
    font-weight: bold;
    padding-bottom: 10px;
}

.j_blog {
    min-height: 198px;
    border: 1px solid #FFFFFF;
    background: #DFDFDF;
    /*     
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 5px 1px rgba(40,48,72,0.13); */

}

.j_parablog {
    font-size: 14px;
    line-height: 20px;
    font-weight: lighter;
    color: #000000;
    margin-top: 10px;
}

.j_bloglast {
    font-size: 16px;
    line-height: 16px;
    color: #283048;
    opacity: 6.4;
}

.j_bloglastpara {
    margin-top: 44px;
    width: max-content;
}

.j_bloglastdate {
    margin-top: 44px;
    text-align: right;
}

.j_mapview {
    min-height: 645px;
    border: 1px solid #FFFFFF;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(40, 48, 72, 0.13);

}


.mapouter {
    position: relative;
    text-align: right;
    height: 645px;
    width: 600px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 645px;
    width: 600px;
}

.j_checkbox {
    background: #F4F4F4;
    border: 1px solid #F4F4F4;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
    height: 25px;
    color: #283048;
    padding-left: 13px;
    padding-top: 4px;
    width: 22%;
    margin: 7px;
}

.j_chkbordermap {
    border: 1px solid rgba(240, 78, 30, 0.23);
    background: #FEF6F4;
}


.j_about {
    min-height: 198px;
    border: 1px solid #FFFFFF;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 1px rgba(40, 48, 72, 0.13);
}

.j_aboutpara {
    font-size: 14px;
    line-height: 22px;
    color: #676767;
    font-weight: lighter;
}



.j_formbox {
    min-height: 346px;
    margin-left: -20px;
    padding: 22px 7px;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.13);
    background-color: #ffffff;

}

.j_formheading {
    font-size: 20px;

    font-weight: 600;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;

    letter-spacing: normal;

    color: #000000;
    padding-bottom: 40px;

    font-family: "Proxima Nova", sans-serif;

}

.j_formheading_modal {
    font-size: 20px;

    font-weight: 600;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;

    letter-spacing: normal;

    color: #000000;
    padding-top: 20px;

    font-family: "Proxima Nova", sans-serif;
    text-align: center;

}

.form-control {
    height: 52px !important;
    /* border-radius: 4px 4px 0 0 !important; */
}


.j_footertor {
    background: #1D1C41;
}

.j_fhead {
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    font-weight: bold;
    margin-left: 38px;
}

.j_afooter {
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    color: rgba(218, 224, 229, 0.77);
    font-family: 'Proxima Nova Regular', sans-serif;
}

.j_afooter:hover {
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    color: #FFFFFF;
    font-weight: lighter;
}

.j_fontfooter {
    margin-left: 34px;
    margin-top: 25px;
    font-size: 45px;
}

.j_floorpara {
    margin-top: -20px;
    font-size: 14px;
    color: #1982C8;
    font-weight: bold;
    text-align: center;
}

.j_floorparaf {
    /* margin-top: 10px; */
    font-size: 14px;
    color: #1982C8;
    font-weight: bold;
    text-align: center;
}

.j_fpara {
    font-size: 16px;
    line-height: 14px;
    color: rgba(103, 103, 103, 0.67);
}

.overview-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

/* .row{
    margin-left: 0px !important;
    margin-right: 0px !important;
} */
.j_fcontent {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    font-family: 'Proxima Nova', sans-serif;
    padding-top: 12px;

}

.j_ultab {
    /* margin-left: -41px; */
    margin-bottom: 27px;
}

.j_listborder1 {
    border: 1px solid #EFEFEF;
    border-bottom: 2px solid #1982C8;
    border-radius: 2px 0 0 2px;
    list-style: none;
    width: 131px;
    height: 71px;
    float: left;
    background: #F4F4F4;
}

.j_listborder2 {
    border: 1px solid #F0EEEE;
    border-radius: 2px 0 0 2px;
    width: 131px;
    height: 71px;
    list-style: none;
    /* margin-left: 130px; */
    background: #F4F4F4;
}

.J_florimg {
    margin-top: -15px;
    width: 66px;
    height: 48px;
}


.j_amentiespara {
    font-size: 12px;
    line-height: 14px;
    color: rgba(40, 48, 72, 0.67);
    font-weight: lighter;
    margin-left: 18px;
    text-align: center;
}

.j_hhh {
    font-size: 47px;
    padding: 12px;
    margin-left: 18px;
    color: rgba(40, 48, 72, 0.67);
    margin-top: -5px;
}

.j_footerline {
    border-top: 1px solid #FFFFFF;
    /* width: 1049px; */
    margin-left: 261px;
    color: #D3D3D3;
}

.j_footerpara {
    font-family: 'Proxima Nova Regular', sans-serif;
    font-size: 17px;
    line-height: 28px;
    color: rgba(218, 224, 229, 0.77);
    margin-left: 35px;
    text-align: initial;
}

.j_footerhead {
    text-align: left;
    font-size: 32px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: -30px;
    margin-left: 35px;
}

.j_crahead {

    font-size: 21px;
    line-height: 22px;
    font-weight: bold;
    color: #283048;
}

.j_crasubhead {
    font-size: 15px;
    line-height: 16px;
    color: rgba(103, 103, 103, 0.67);
    font-family: "Proxima Nova Regular", sans-serif;

}

.j_crasubhead_price {
    font-family: "Proxima Nova Semibold", sans-serif;
    color: #777777;
    font-weight: bold;
}

.carousel-item a {
    text-decoration: none;
}

.j_read {
    font-size: 12px;
    line-height: 14px;
    background: #A5A5A5;
    color: white;
    /* font-weight: bold; */
    padding: 4px 8px;
    text-align: center;
    max-width: 70px;
}

.j_headown {
    font-size: 12px;
    line-height: 14px;
    background: #F6F5F5;
    font-weight: lighter;
    color: black;
    padding: 4px 12px;
    /* max-width: 220px; */
    text-align: center;
}

.j_head {
    font-size: 16px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.67);
    font-family: "Proxima Nova Regular", sans-serif;
}

.controls-top {
    text-align: right;
    padding-right: 0;
}

.card {
    border: none !important;
    border-radius: .25rem !important;
}

.j_btn {
    width: max-content;
    height: 44px;
    /* margin: 16px 238px 0 0; */
    /* padding: 12px 24px; */
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(205, 205, 205, 0.5);
    /* background-color: #821974; */
    font-family: "Proxima Nova", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    border: 0px !important;

}

.j_btn:focus,
.j_btn:active {
    border: 0px !important;
}

button:focus {
    outline: 0px !important;
    outline: 0px !important;
}

.j_btn_mob {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(153, 153, 153, 0.5);
    background-color: #f04e1e;
    display: flex;
    align-items: center;
    font-size: 16px;

    font-weight: 600;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;

    letter-spacing: normal;
    justify-content: center;
    color: #ffffff;
    font-family: "Proxima Nova Regular", sans-serif;
    border: 0px !important;
}

.footer-btn-div {
    width: 47%;
}

.footer-btn {
    width: 90%;
    margin: 0 auto;
}

.imgheight {
    height: 216px;
}

.hii {
    background: #757575;
    padding: 11px;
    margin: 2px;
    color: white;
}

.j_modalhead {
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    color: #283048;
    font-family: "Proxima Nova", sans-serif;
    text-align: center;
    padding-top: 22px;
    text-align: left;
    padding-left: 24px;
    opacity: 0.8;
    margin-bottom: 7px;
}

.j_forminputf {
    /* width: 355px; */
    height: 48px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(205, 205, 205, 0.5);
    border: solid 1px #e7e4e4;
    background-color: #ffffff;
    font-family: "Proxima Nova", sans-serif;
    margin-bottom: 24px;
    /* border-radius: 4px 4px 0 0 !important; */
}

.top-border-radius {
    border-radius: 4px 4px 0 0;
}

.j_forminputs {
    border: 1 solid #E7E4E4 !important;
    /* border-radius: 4px 4px 0 0 !important; */
}

.j_forminputt {
    border: 1px solid #E7E4E4 !important;
    /* border-radius: 0 0 4px 4px !important; */
}

.showBtnAtMobile {
    display: none;
}

.social {
    height: 45px;
    width: 45px;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding-left: 0;
    margin-right: 13px;
}

.social img {
    margin-top: -30px;
}

.no-border-radius {
    border-radius: 0;
    border-width: 0 1px 0 1px
}

.top-border-radius {
    border-radius: 4px 4px 0 0;
}

.bottom-border-radius {
    border-radius: 0 0 4px 4px;
}

.HeartShape {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color: #1982C8;
    text-align: center;
    font-size: 13px;
    line-height: 1.9;
}

.reraPosition {
    float: right;
    padding-right: 15px;
    position: relative;
    bottom: 30px;
}

@media (min-width: 200px) and (max-width: 575.98px) {
    .react-responsive-modal-modal{
        /* max-width: 500px !important; */
        width: 90% !important;
      }
    .footer-container {
        padding: 0px !important;
        padding: 0px 16px !important;
    }

    .btn-container {

        width: 60px;
        display: flex !important;
        height: 60px;
        padding: 8px 0 0;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 23%);
        background-color: #fff;
        POSITION: FIXED !important;
        BOTTOM: 16px !important;
        bottom: 16px;
        justify-content: center;
        border-radius: 30px;
        right: 16px;
        border: 0px !important;
        align-items: center;


    }

    .j_overpara {
        font-size: 14px;
    }

    .j_llsecond {
        margin-right: 20px;
        margin-top: -60px;
    }

    .j_llfirst {
        margin-right: 60px;
        margin-top: -60px;
    }

    #divContactForm {
        display: none;
        visibility: hidden;
    }

    .showBtnAtMobile {
        position: fixed;
        bottom: 3px;
        width: 100%;
        margin-left: 0px !important;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        background: white;
        overflow-x: hidden !important;
        padding-right: 1.5%;

    }

    .positionAtMobile {
        margin-bottom: 60px;
    }

    .hideAtBottom {
        display: none;
    }

    .footer-copyright {
        font-size: 10px;
        padding-top: 5px;
    }

    .j_checkbox {
        width: 44%;
    }

    .j_telephone {
        margin-top: -55px;
    }

    .reraPosition {
        bottom: 8px;
        margin-bottom: 10px;
    }

    .j_footerline {
        margin-top: 40px;
    }

    /* .j_btn{
        width: 92%;
    } */
}

.btn-container {
    display: none;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .footer-container {
        padding: 0px !important;
        padding: 0px 16px !important;
    }

    .j_llsecond {
        margin-right: 20px
    }

    .j_llfirst {
        margin-right: 60px;
    }

    #divContactForm {
        display: none;
        visibility: hidden;
    }

    .btn-container {
        width: 60px;
        display: flex !important;
        height: 60px;
        padding: 8px 0 0;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 23%);
        background-color: #fff;
        POSITION: FIXED !important;
        BOTTOM: 16px !important;
        bottom: 16px;
        justify-content: center;
        border-radius: 30px;
        right: 16px;
        border: 0px !important;
        align-items: center;
    }

    .mob-right {
        right: 4% !important;
    }

    .showBtnAtMobile {
        position: fixed;
        bottom: 3px;
        width: 100%;
        margin-left: 0px !important;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        background: white;
        overflow-x: hidden !important;
        padding-right: 1.5%;

    }

    .positionAtMobile {
        margin-bottom: 60px;
    }

    .hideAtBottom {
        display: none;
    }

    .footer-copyright {
        font-size: 12px;
        padding-top: 5px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .hideAtBottom {
        display: none;
    }

    .footer-copyright {
        font-size: 13px;
        padding-top: 5px;
    }

    .j_telephone {
        width: max-content;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Whatsapppp */
.whatsapp-div {

    float: right;
    cursor: pointer;
    position: fixed;
    right: 3%;

    top: 90%;
}


.whatsapp-icon {
    height: 45px;
    margin-top: 1%;
}

.whatsapp-div-mobile {
    display: none;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .whatsapp-div-mobile {
        float: left;
        border-radius: 25px;
        cursor: pointer;
        position: fixed;
        bottom: 5%;
        display: block;
        text-align: center;
        padding-top: 3.5%;
        right: 5%;
        z-index: 9999 !important;
    }

    .whatsapp-icon {
        height: 40px;

    }

    .whatsapp-div {
        display: none;
    }
}

@media (min-width: 200px) and (max-width: 575.98px) {
    .whatsapp-div-mobile {
        float: left;
        border-radius: 25px;
        cursor: pointer;
        position: fixed;
        bottom: 5%;
        display: block;
        text-align: center;
        padding-top: 3.5%;
        right: 5%;
        z-index: 9999 !important;
    }

    .whatsapp-icon {
        height: 40px;

    }

    .whatsapp-div {
        display: none;
    }
}


/* END */
/* Coontact Pop up */
.chat-header-contact-modal {

    /* height: 206px; */
    margin: 0 0 10px;

    /* border-radius: 8px; */
    background-color: #0c1d29;
}

.chat-modal-logo {
    height: 77px;
    margin: 0px 40%;
    margin-left: auto;
    margin-right: auto;
    display: block;


}

.chat-modal-hello {

    /* font-family: ProximaNova; */
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: #ffffff;
    text-align: center;
    padding-top: 16px;
}

.chat-modal-header-main-div {
    width: 100%;
    /* padding: 0px 35%; */

}

.schedule-your-free-visit {

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.72);
    text-align: center;

}

.modal-header .close {
    color: white;
}

.j_btn-call {
    height: 56px;
    background: #de492a;
    border-radius: 8px;
    width: 95%;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 400;
    border: none;
    margin-top: 12px;
}

.j_btn_font {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 400;
    border: none;
    margin-top: 12px;
}

.j_btn_font:hover {
    color: #FFFFFF !important;
    text-decoration: none !important;
}

.web-only {
    display: block;
}

/* SP New - 17th Dec-2020 */
.sp-icon-circle-profit {
    width: 14px;
    height: 14px;
    margin: 0px 3px 0px 10px;
    padding: 3px;
    background-color: #0087bf;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sp-icon-circle-loss {
    width: 14px;
    height: 14px;
    margin: 0px 3px 0px 10px;
    padding: 3px;
    background-color: #d0021b;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sp-icon {
    color: white;
    font-size: 10px;
}

.sp-profit-text {
    height: 10px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #0087bf;
}

.sp-loss-text {
    height: 10px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #d0021b;
}

.sp-duration-text {
    margin: 3px 0 3px 2px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;

}

.mob-rera {
    display: block !important;
}

.mob-only {
    display: none;
}

.sp-mobile {

    padding-left: 0px !important;
    float: left;

}

@media (max-width: 575.98px) {
    .web-only {
        display: none;
    }

    .mob-only {
        display: block !important;
    }

    .sp-icon-circle-loss {
        width: 8px;
        height: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sp-icon-circle-profit {
        width: 8px;
        height: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sp-icon {
        color: white;
        font-size: 6px;
    }

    .sp-profit-text {
        font-size: 10px;
    }

    .sp-loss-text {
        font-size: 10px;
    }

    .sp-duration-text {
        font-size: 9px;
    }
}

/* Changes */
.overview-main-div {
    width: 100%;
    height: max-content;
    /* padding: 24px 0; */
    box-shadow: 0 2px 12px 0 rgba(40, 48, 72, 0.13);
    background-color: #ffffff;
    margin-bottom: 24px;
}

.overview-head {
    height: 72px;
    border-bottom: 1px solid #eeebeb;
    padding: 0px 24px !important;

}

.col-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.overview-youtube-video {
    height: 100%;
    margin-bottom: 25px;
    width: 100%;
    /* background: #000;
      display: flex;
      justify-content: center;
      align-items: center; */
}

.yt-modal-body {
    height: 312px;
    width: 100%;
}

.yt-play-btn:focus,
.yt-play-btn:active,
.yt-play-btn:hover {
    border: 0px !important;
}

.overview-desc {
    padding-top: 32px;
    padding-bottom: 24px;
}

.over-view-btn {
    width: max-content;
    height: 24px;
    border-radius: 4px;
    /* border: solid 1px #f04e1e; */
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #f04e1e;
    margin-bottom: 20px;
}

.price-breakup {
    width: 102px;
    height: 28px;
    border-radius: 4px;
    background-color: #f04e1e !important;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: 0px;
}

.pricing-download {
    width: 14px;
    height: 16px;
    border-radius: 2px;
    border: solid 0.5px #000000;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-body {
    /* background-color: #f2f2f2; */
    width: 100%;
    padding: 24px 80px;
    padding-bottom: 10px;
    /* margin-top: 65px; */
}

.slider-body {
    padding: 0px 80px;
}

.header-links {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    font-family: "Proxima Nova Semibold", sans-serif;
    text-transform: uppercase;
}

.sidebar-links {
    font-size: 16px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    font-family: "Proxima Nova Semibold", sans-serif;
    /* text-transform: uppercase; */
}

.header-center {
    height: 100%;
    display: flex;
    align-items: center;
}

/* #header-nav .nav-item.active{
      border-bottom: 4px solid #f04e1e;
  } */
.banner-img {
    height: 500px;
    width: 100%;
    position: relative;
}

.banner-right-img {
    height: 500px;
    width: 100%;
    background: url(../images/image.png);
}

.banner-img-demo {
    /* background-image: url(../images/demo-banner.jpeg) ; */
    background-repeat: no-repeat;
    width: 100% !important;
    background-size: contain;
}

/* .banner-left-btn-main{
    position: absolute;
    bottom: 40%;
    left: 5%;
} */
.brochure-modal-title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    text-align: center;
    width: 100%;
}

.modal-header.brochure-modal-header .close {
    color: rgba(0, 0, 0, 0.67) !important;
}

.modal-header.brochure-modal-header {
    border-bottom: 0px !important;
}

.brochure-modal-content {
    border-radius: 8px !important;
}

.brochure-modal-body {
    margin-top: 15px;
    padding: 24px 24px;
}

.hide-mob {
    display: block;
}

.mob-only {
    display: none;
}

@media (min-width: 200px) and (max-width: 575.98px) {
    .main-body {
        width: 100%;
        padding: 0px;
        margin-top: 24px !important;
    }

    /* New  */
    .slider-body {
        width: 100%;
        padding: 0px 16px;
    }

    .slider-img-overview-head {
        font-size: 20px !important;
    }

    .slider-img-overview-loc {
        font-size: 16px !important;
    }

    .slider-img-overview-price {
        font-size: 18px !important;
    }

    .slider-img-overlay-div {
        height: max-content !important;
        padding: 16px 17px !important;
    }

    .slider-img-overview-btn-div {
        justify-content: left;
        margin-top: 6px;
    }

    .slider-img-overview-btn {
        height: 30px !important;
    }

    .more-info-div1 {
        width: 100% !important;
    }

    .more-info-div2 {
        width: 100% !important;
        justify-content: flex-start !important;
    }

    .needmore-main-div {
        display: block !important;
    }

    /* New Mob Changes july 2021 */

    .j_heighthead {
        height: 56px;
    }

    .hide-mob {
        display: none !important;
    }

    .mob-only {
        display: block !important;
    }

    .menu-container {
        display: inline-block;
        cursor: pointer;
    }

    .bar1,
    .bar2,
    .bar3 {
        width: 25px;
        height: 2px;
        background-color: #333;
        margin: 6px 0;
        transition: 0.4s;
    }

    .change {

        opacity: 0.6;
    }

    .j_para {
        font-size: 12px;
    }

    .j_overviewhead {
        font-size: 20px;
    }

    .j_fcontent {
        font-size: 12px;
    }

    .j_similerhead {
        font-size: 20px;
        padding-left: 16px;
    }

    /* .brochure-modal-content{
          margin-right: 5% !important;
      } */
    #multi-item-example1 {
        padding: 16px;
    }
}

.sidebar-main {

    height: 100vh;
    overflow-y: scroll;
    transition: all 0.3s;

    z-index: 1001;
    position: fixed !important;
    left: 0px !important;
    top: 56px;
    width: 60%;
    /* background-color: #1D1C41; */
    color: #fff;
    padding: 56px 15px;

}

.close-div-sidebar {
    position: absolute;
    top: 1%;
    right: 1%;
}

.overview-video-div {
    height: 312px;
    width: 100%;
    padding: 24px;
}

.overview-about-builder {
    padding: 0px 24px;
}

.floor-plan-table-div {
    border: solid 1px #c5c5c5;
    background-color: #ffffff;
    /* margin-bottom: 24px; */
    /* margin: 0px 24px; */
}

.floor-plan-row {
    height: 60px;
    display: flex !important;
    padding: 0px 14px;
    align-items: center;
}

.floor-plan-td-row-odd {
    height: 60px;
    display: flex;
    padding: 0px 14px;
    align-items: center;
    background-color: #f2f2f2;
}

.floor-plan-td-row-even {
    height: 60px;
    display: flex;
    padding: 0px 14px;
    align-items: center;
    background-color: #fff;
}

/* New Styles */
.head-with-border {
    height: 72px;
    border-bottom: 1px solid #eeebeb;
    display: flex;
    align-items: center;
    padding: 0px 24px;
}

.head-with-no-border {
    height: 72px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
}

.head-all-div {
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1d1c41;

}

.div-body {
    /* padding: 0px 24px ; */
    width: 92%;
    margin: 0 auto;
    margin-top: 24px;
}

.overview-content {
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #676767;
    /* margin-top: 32px; */
    margin-bottom: 24px;
}

.site-plan-sub-head {
    font-family: "Proxima Nova", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 24px;
}

.site-plan-imges {
    height: 206px;
}

.margonTop {
    margin-top: 24px;
}

.floor-plan-main-div {
    height: 206px;
    border: solid 1px #e2e2e2;
    background-color: #ffffff;
}

.floorplan-img-div {
    height: 120px;
}

.floor-plan-img-div {
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.floor-unit-details-div {
    height: 25%;
    /* background-color: #f04e1e; */
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Gallery */
.gallery-img {
    width: 100%;
    height: 100%;
}

.gallery-col {
    margin-bottom: 24px;
}

.gallery-row {
    width: 100%;
    margin: 0px;
}

.gallery-img-main-div {
    display: flex;
    flex-wrap: wrap;
}

.gallery-img-div {
    height: 162px;
    width: 33.33%;
    padding-right: 24px;
    margin-bottom: 24px;
}

/* Location */
.map-view-head {
    font-family: "Proxima Nova", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
}

.location-view {
    height: 197px;
    width: 100%;
    margin-top: 16px;
}

.location-icon {
    color: #f04e1e;
}

.location-desc {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #000000;
    font-family: "Proxima Nova", sans-serif;
}

.location-desc-div {
    display: flex;
}

.location-col {
    margin-bottom: 24px;
}


/* Footer */
.footer-main-div {
    height: max-content;
    background-color: #dae3e7;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
}

.footer-container {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 80px;
}

.footer-content {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
    font-family: "Proxima Nova", sans-serif;
    width: 95%;
}

.col-left-padding {
    padding-left: 0px;
}

.col-right-padding {
    padding-right: 0px;
}

/* Aminities */
.aminity-col {
    display: flex;
    flex-wrap: wrap;
}

.aminity-img {

    margin-left: 12px;
    margin-bottom: 12px;
    width: 126px;
    height: 126px;
    border: solid 1px #f0eeee;
    background-color: #f4f4f4;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding-top: 3%;
}

.aminities-name {
    font-family: "Proxima Nova", sans-serif;

    font-size: 12px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;

    letter-spacing: normal;
}

.aminity-padd-top {
    margin-top: 40px;
}

@media (min-width: 576px) and (max-width: 767.98px) {

    /* New  */
    .slider-body {
        width: 100%;
        padding: 0px 16px;
    }

    .slider-img-overview-head {
        font-size: 20px !important;
    }

    .slider-img-overview-loc {
        font-size: 16px !important;
    }

    .slider-img-overview-price {
        font-size: 18px !important;
    }

    .slider-img-overlay-div {
        height: max-content !important;
        padding: 16px 17px !important;
        margin-top: -6% !important;
    }

    .slider-img-overview-btn-div {
        justify-content: left;
        margin-top: 6px;
    }

    .slider-img-overview-btn {
        height: 30px !important;
    }

    .more-info-div1 {
        width: 100% !important;
    }

    .more-info-div2 {
        width: 100% !important;
        justify-content: flex-start !important;
    }

    .needmore-main-div {
        display: block !important;
    }

    /* New Mob Changes july 2021 */
    .head-with-border,
    .head-with-no-border {
        height: 52px;
        padding: 0px 16px;
    }

    .head-all-div {
        font-size: 20px;
    }

    .overview-content {
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
    }

    .overview-youtube-video {
        height: 369px;
        margin-bottom: 24px;
        width: 100%;
    }

    .embed-responsive-16by9::before {
        background: black !important;
    }

    .j_overviewhead {
        font-size: 16px !important;
    }

    .j_aboutpara {
        font-size: 14px;
        line-height: 22px;
    }

    .floor-plan-row {
        height: 44px;
        width: 100%;
        align-items: center;
        padding: 0px 0px;
    }

    .floor-plan-td-row-odd,
    .floor-plan-td-row-even {
        height: 44px;
        width: 100%;
        align-items: center;
        padding: 0px 0px;
    }

    .j_fpara {
        font-size: 12px;
    }

    .price-breakup {
        width: max-content !important;
        font-size: 8px !important;
        display: flex;
        align-items: center;
        text-align: left;
    }

    .div-body {
        width: 95%;
        margin: 0 auto;
    }

    .site-plan-sub-head {
        font-size: 16px;
        padding-top: 16px;
    }

    .gallery-col {
        margin-bottom: 8px;
    }

    .map-view-head {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }

    .location-col {
        margin-bottom: 12px;
    }

    .footer-content {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }

    .mob-bottom {
        padding-bottom: 50px !important;
    }

    .footer-main-div {
        /* height: 100px; */
        width: 100%;
        /* margin-bottom: 60px !important; */
    }

    .aminity-img {
        height: 103px;
        width: 103px;
        /* margin-left: 8px; */
        margin-bottom: 8px;
    }

    .aminity-padd-top {
        margin-top: 16px;
    }

    .gallery-img {
        height: 100%;
        width: 100%;
    }

    .gallery-img-main-div {
        margin-top: 16px;
    }
}

@media (min-width: 200px) and (max-width: 575.98px) {

    .head-with-border,
    .head-with-no-border {
        height: 52px;
        padding: 0px 16px;
    }

    .head-all-div {
        font-size: 20px;
    }

    .overview-content {
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
    }

    .overview-youtube-video {
        height: 369px;
        margin-bottom: 24px;
        width: 100%;
    }

    .j_overviewhead {
        font-size: 16px !important;
    }

    .j_aboutpara {
        font-size: 14px;
        line-height: 22px;
    }

    .floor-plan-row {
        height: 44px;
        width: 100%;
        align-items: center;
        padding: 0px 0px;
    }

    .floor-plan-td-row-odd,
    .floor-plan-td-row-even {
        height: 44px;
        width: 100%;
        align-items: center;
        padding: 0px 0px;
    }

    .j_fpara {
        font-size: 12px;
    }

    .price-breakup {
        width: 100% !important;
        font-size: 8px !important;
        display: flex;
        align-items: center;
        text-align: left;
    }

    .div-body {
        width: 92%;
        margin: 0 auto;
    }

    .site-plan-sub-head {
        font-size: 16px;
        padding-top: 16px;
    }

    .gallery-col {
        margin-bottom: 8px;
    }

    .map-view-head {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }

    .location-col {
        margin-bottom: 12px;
    }

    .footer-content {
        font-size: 12px;
        line-height: 20px;
        width: 100%;
    }

    .mob-bottom {
        padding-bottom: 50px !important;
    }

    .footer-main-div {
        height: max-content;
        width: 100%;
        /* margin-bottom: 60px !important; */
    }

    .aminity-img {
        height: 103px;
        width: 103px;
        /* margin-left: 8px; */
        margin-bottom: 8px;
    }

    .aminity-padd-top {
        margin-top: 16px;
    }

    .gallery-img {
        height: 100%;
        width: 100%;
    }

    .gallery-img-main-div {
        margin-top: 16px;
    }
}

/* Banner New */
.banner-left-main-div {
    height: max-content;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    width: 26%;
    padding: 10px 0 32px;
    display: none;
}

.booking-open-main-div {
    width: 60%;
    height: 36px;
    border-radius: 2px;
    background-color: #f04e1e;
    margin: 0 auto;
    font-family: "Proxima Nova", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-prop-details-main {
    padding-bottom: 31px;
    padding-top: 24px;
    margin: 0 auto;
}

.banner-prop-name {
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
}

.banner-prop-desc {
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-top: 6px;
    width: 75%;
    margin: 0 auto;
}

.banner-builder-name {
    font-family: "Proxima Nova", sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-top: 6px;
    width: 65%;
    margin: 0 auto;
}

.banner-limited-period-main {
    height: 96px;
    padding: 8px 4px 4px;
    background-color: #f04e1e;
    margin-bottom: 16px;
}

.banner-offer-details {
    height: 56px;
    width: 100%;
    margin: 0 auto;
    border-radius: 2px;
    background-color: #000000;
    display: flex;
}

.header-margin-right {
    margin-right: 5px;
}

.banner-offer-title {

    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    font-family: "Proxima Nova", sans-serif;
    text-align: center;
    padding-bottom: 8px;
}

.banner-offer-desc {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin: auto;
    /* animation-duration: 3s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate; */

}

@keyframes slidein {
    from {
        margin-left: 50%;
        width: 300%;
    }

    to {
        margin-left: -25%;
        width: 100%;
    }
}

.banner-save-main {
    height: 36px;
    padding: 8px 4px 4px;
    background-color: #f04e1e;
    margin-bottom: 16px;
}

.banner-left-desc {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 80%;
    margin: auto;
    margin-bottom: 16px;

}

.banner-left-btn-main {
    width: max-content;
    margin: 0 auto;
}

.banner-left-btn {
    height: 36px;
    border-radius: 4px;
    border: solid 1px #4caf50;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 12px;
}

.banner-left-btn-variant {
    height: 36px;
    border-radius: 4px;
    border: solid 1px #4caf50;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    position: absolute;
    top: 50%;
    left: 5%;
}

.banner-left-btn-variant-mobile {
    height: 36px;
    border-radius: 4px;
    border: solid 1px #4caf50;
    background-color: rgba(0, 0, 0, 0.8);
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;

}

.banner-left-main-div-mobile-variant {
    width: 100%;

    position: absolute;
    top: 40%;
    left: 10%;
    height: max-content !important;
}

@media (min-width: 200px) and (max-width: 575.98px) {
    .banner-img {
        height: 260px;
    }

    .j_kkk {
        height: 30px;
        width: 30px;
    }

    .mob-right {
        right: 6% !important;
    }

    .banner-left-main-div {
        width: 100%;
        background-color: #fff;
        padding-top: 0px;
        margin-bottom: 32px;
        height: max-content !important;
    }

    .booking-open-main-div {
        width: 100%;
        height: 28px;
        font-size: 16px;
    }

    .banner-prop-name {
        font-family: ProximaNova;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #f04e1e;
        font-family: "Proxima Nova", sans-serif;
    }

    .banner-prop-details-main {
        padding-top: 16px;
    }

    .banner-prop-desc {
        color: #000;
        text-align: center;
    }

    .banner-builder-name {
        color: #000;
    }

    .posession-main-div {
        height: 36px;
        width: 100% !important;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        width: 92%;
        margin: 0 auto;
        padding-left: 16px;
        /* justify-content: center; */
    }

    .possesion-text {
        font-family: ProximaNova;
        font-size: 12px;
        font-weight: normal;
        color: #000000;
        font-family: "Proxima Nova", sans-serif;
    }

    .banner-left-desc {
        color: #000;
    }

    .banner-left-btn-main {
        display: flex;
        justify-content: center;
    }

    .gallery-img-div {
        height: 130px !important;
        width: 50% !important;
        padding-right: 8px !important;
        margin-bottom: 8px !important;
    }

    .overview-main-div {
        margin-bottom: 12px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .banner-img {
        height: 260px;
    }

    .banner-left-main-div {
        width: 100%;
        background-color: #fff;
        padding-top: 0px;
        margin-bottom: 32px;
        height: max-content !important;
    }

    .booking-open-main-div {
        width: 100%;
        height: 28px;
        font-size: 16px;
    }

    .banner-prop-name {
        font-family: ProximaNova;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #f04e1e;
        font-family: "Proxima Nova", sans-serif;
    }

    .banner-prop-details-main {
        padding-top: 16px;
    }

    .banner-prop-desc {
        color: #000;
        text-align: center;
    }

    .banner-builder-name {
        color: #000;
    }

    .posession-main-div {
        height: 36px;
        width: 100% !important;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        width: 92%;
        margin: 0 auto;
        padding-left: 16px;
        /* justify-content: center; */
    }

    .possesion-text {
        font-family: ProximaNova;
        font-size: 12px;
        font-weight: normal;
        color: #000000;
        font-family: "Proxima Nova", sans-serif;
    }

    .banner-left-desc {
        color: #000;
    }

    .banner-left-btn-main {
        display: flex;
        justify-content: center;
    }

    .gallery-img-div {
        height: 130px !important;
        width: 33.33% !important;
        padding-right: 8px !important;
        margin-bottom: 8px !important;
    }

    .overview-main-div {
        margin-bottom: 12px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .main-body {
        width: 100%;
        padding: 0px;
        margin-top: 24px !important;

    }

    .j_heighthead {
        height: 56px;
    }

    .hide-mob {
        display: none !important;
    }

    .mob-only {
        display: block !important;
    }

    .menu-container {
        display: inline-block;
        cursor: pointer;
    }

    .bar1,
    .bar2,
    .bar3 {
        width: 25px;
        height: 2px;
        background-color: #333;
        margin: 6px 0;
        transition: 0.4s;
    }

    .change {

        opacity: 0.6;
    }

    .j_para {
        font-size: 12px;
    }

    .j_overviewhead {
        font-size: 20px;
    }

    .j_fcontent {
        font-size: 12px;
    }

    .j_similerhead {
        font-size: 20px;
        padding-left: 16px;
    }

    /* .brochure-modal-content{
      margin-right: 5% !important;
  } */
    #multi-item-example1 {
        padding: 16px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .footer-main-div {
        height: max-content;
    }

    .header-margin-right {
        margin-right: 0px;
    }

    .footer-content {
        width: 100%;
        font-size: 10px;

    }

    .j_btn {
        font-size: 20px;
    }

    .j_telanchor {
        font-size: 10px;
    }

    .header-padding-col {
        padding-right: 0px;
    }

    .header-links {
        font-size: 8px;
    }

    .banner-left-main-div {
        width: 45%;
    }

    .sticky-form {
        right: -3px;
        top: 10%;
    }

    .main-body {
        padding: 24px 40px;
    }

    .footer-container {
        padding: 56px 40px;
    }

    .slider-body {
        padding: 0px 40px !important;
    }

    /* New */
    .slider-img-overview-head {
        font-size: 20px !important;
    }

    .slider-img-overview-loc {
        font-size: 16px !important;
    }

    .slider-img-overview-price {
        font-size: 18px !important;
    }

    .slider-img-overlay-div {
        height: max-content !important;
        padding: 16px 17px !important;
    }

    .slider-img-overview-btn-div {
        justify-content: left;
        margin-top: 6px;
    }

    .slider-img-overview-btn {
        height: 30px !important;
    }

    .hide {
        display: none !important;
    }

    /*  */
    .head-all-div,
    .j_overviewhead {
        font-size: 20px;
    }

    .j_fpara,
    .j_fcontent {
        font-size: 12px;
    }

    .price-breakup {
        width: 100% !important;
        font-size: 10px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .j_telanchor {
        font-size: 14px;
    }

    .header-margin-right {
        margin-right: 0px;
    }

    .footer-main-div {
        /* height: 100px; */
    }

    .j_telephone {
        width: max-content;
        font-size: 11px;
    }

    .header-padding-col {
        padding-right: 0px;
    }

    .header-links {
        font-size: 11px;
    }

    .banner-left-main-div {
        width: 35%;
    }

    .sticky-form {
        right: -3px;
        top: 15%;
    }

    .footer-container {
        padding: 56px 40px;
    }

    .main-body {
        padding: 24px 40px;
    }

    /* New */
    .slider-body {
        padding: 0px 40px;
    }

    /* .slider-img-overlay-div{
        margin-top: -25% !important;
    } */
    .head-all-div,
    .j_overviewhead {
        font-size: 22px;
    }

    .j_fpara,
    .j_fcontent {
        font-size: 14px;
    }

    .price-breakup {
        width: 100% !important;
        font-size: 12px !important;
    }
}

@media (min-width: 1200) and (max-width: 1310) {
    .j_telanchor {
        font-size: 12px;
    }

    .header-margin-right {
        margin-right: 0px;
    }

    .header-padding-col {
        padding-right: 0px;
    }

    .header-links {
        font-size: 12px;
    }

    /* .banner-left-main-div{
        width: 45%;
    } */
    .sticky-form {
        right: 20px;
        top: 15%;
    }

    .main-body,
    .footer-container,
    .slider-body {
        padding: 56px 40px;
    }

    .head-all-div,
    .j_overviewhead {
        font-size: 24px;
    }

    .j_fpara,
    .j_fcontent {
        font-size: 16px;
    }

    .price-breakup {
        width: 100% !important;
        font-size: 12px !important;
    }

}

/* New Design July 2021 */
/* Slidder */
.slider-img-overlay-div {
    height: 178px;
    border-radius: 4px;
    background-color: #ffffff;
    /* position: absolute;
    bottom: -15%;
    width: 100%; */
    padding: 40px 24px;
    margin-top: -10%;
    /* display: flex;
    align-items: center; */
}

.slider-img-overlay {
    height: 100%;
}

.slider-img-overview-head {
    font-family: "Proxima Nova", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: normal;
    color: #1d1c41;
}

.slider-img-overview-loc {
    font-family: "Proxima Nova", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: rgba(52, 52, 52, 0.67);
    margin-bottom: 12px;
}

.slider-img-overview-price {
    font-family: "Proxima Nova", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #283048;
}

.slider-img-overview-btn {
    height: 36px;
    border: 0px !important;
    border-radius: 4px;
    font-family: "Proxima Nova", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-left: 13px;
    padding-right: 13px;

}

.slider-img-overview-btn-div {
    display: flex;
    /* justify-content: space-around; */
    align-items: flex-end;
}

.needmore-main-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
}

.form-label-contact {
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000;
}

.more-info-div1 {
    width: 40%;
}

.more-info-div2 {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.hide {
    display: block;
}